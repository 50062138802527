import React from 'react'

export const CatalogueButton = props => {
	const handleOnClick = () => {
		props.callback()
	}

	const { href, button_text, button_color } = props

	const month = href.substring(36, href.length - 8)

	const DefaultText = () => (
		<span>
			Ver catálogo <br />
			de {month}
		</span>
	)

	return (
		<div className='flex flex-col items-center justify-center'>
			<div onClick={handleOnClick}>
				<button
					style={{ backgroundColor: button_color }}
					className={`py-[0.55rem]
      px-16
     bg-red-500
      text-white
      shadow-2xl
      rounded-full
      text-2xl
      font-extrabold
      leading-[1.15]
      hover:scale-110
      hover:ring
      transition
      duration-500
      ease-in-out
      uppercase
			max-w-xs`}>
					{button_text ? button_text : <DefaultText />}
				</button>
			</div>
		</div>
	)
}
