/* eslint-disable react-hooks/exhaustive-deps */
import PWAPrompt from 'react-ios-pwa-prompt'
import React, { useEffect, useState } from 'react'
import { CatalogueWindow } from './CatalogueWindow'
import axios from 'axios'
import { CatalogueButton } from './CatalogueButton'
import getIp from '../api/getIp'

const biri = require('biri')

const baseUrl = 'https://ltc.passmedia.es/'
const addVisit = 'api/client/addVisit'
const addDownload = 'api/client/addDownload'
const partnerId = 21

export const Home = () => {
	const [ip, setIp] = useState(null)
	const [clicked, setClicked] = useState(false)
	// const [offlineAPI, setOfflineAPI] = useState(false)
	const [data, setData] = useState(null)
	const [apiData, setApiData] = useState(null)
	const [background, setBackground] = useState('')
	const [clientId, setClientId] = useState(null)

	useEffect(() => {
		getIp(setIp)
		async function getID() {
			const id = localStorage.getItem('ltcClientId')
			if (id) {
				setClientId(id)
			} else {
				const newId = await biri()
				setClientId(newId)
				localStorage.setItem('ltcClientId', newId)
			}
		}
		getID()

		window.addEventListener('appinstalled', handleInstalled)

		return () => {
			window.localStorage.removeItem('pwa_popup_display')
			window.removeEventListener('appinstalled', handleInstalled)
		}
	}, [])

	useEffect(() => {
		axios
			.get(baseUrl + `api/partner/${partnerId}`)
			.then(response => {
				setApiData(response.data)
			})
			.catch(error => {
				// setOfflineAPI(true)
				console.log(error)
			})
	}, [])

	useEffect(() => {
		apiData &&
			setData({
				...data,
				href: apiData.url_catalog,
				button_text: apiData.button_text_splash,
				button_color: apiData.button_color_splash,
				// button_color: '#24619f',
			})
	}, [apiData])

	// useEffect(() => {
	// 	apiData && setBackground(`data:image/jpg;base64,${apiData[1].resource_media}`)
	// }, [apiData])
	useEffect(() => {
		apiData && apiData.have_splash && setBackground(baseUrl + 'storage/' + apiData.splash)
	}, [apiData])

	const handleClick = () => {
		setClicked(true)
	}

	const postVisit =()=>{
		const post = { ip: ip, device_id: clientId, partner_id: partnerId }

		axios
			.post(baseUrl + addVisit, post)
			.then(res => console.log(res.data))
			.catch(error => console.log(error))
	}

	const handleInstalled = () => {
		const post = { ip: ip, device_id: clientId, partner_id: partnerId }
		axios
			.post(baseUrl + addDownload, post)
			.then(res => console.log(res.data))
			.catch(error => console.log(error))
	}
	return (
		<div className='container'>
			{apiData && !apiData.have_splash ? data && <CatalogueWindow href={data.href} callback={postVisit} />  :!clicked ? (
				<div
					style={{
						backgroundImage: `url(${background})`,
						backgroundPosition: 'center',
					}}
					className={`w-screen h-screen pb-24 flex flex-col justify-end bg-default-background bg-cover bg-no-repeat bg-center`}>
					<div>{data && <CatalogueButton {...data} callback={handleClick} />}</div>
				</div>
			) : (
				<CatalogueWindow href={data.href} callback={postVisit} />
			)}

			<PWAPrompt
				promptOnVisit={1}
				timesToShow={100}
				copyTitle='Añade a la Pantalla de Inicio'
				copyBody='Este sitio web tiene funcionalidades de aplicación. Añádelo a tu Pantalla de Inicio.'
				copyShareButtonLabel="1) Presiona el botón 'Compartir'"
				copyAddHomeButtonLabel="2) Presiona 'Añadir a la Pantalla de Inicio'"
				copyClosePrompt='Cerrar'
				permanentlyHideOnDismiss={false}
			/>
		</div>
	)
}
