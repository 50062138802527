import React from 'react'
import Iframe from 'react-iframe'

const CatalogueWindow = ({ href, callback }) => {
	callback && callback()

	return <Iframe url={href} className='w-screen h-screen' />
}

export { CatalogueWindow }
